<template>

  <v-sheet
      style="height: 100%" class="d-flex"
      v-window-resize="() =>getBlockWidth()"
  >

    <!-- Navigation-->
    <v-sheet
        v-if="!(MOBILE_VIEW || SM)"
        class="fill-height"
        style="position: relative"
        color="transparent"
        :min-width="width"
        :max-width="width"
        :width="width"
    >
      <!-- Bottom Trigger -->
      <v-sheet
          v-intersect="intersector"
          style="position:absolute; bottom: 0; left: 0; right: 0" color="transparent"   :width="width" />

      <!-- Navigation content -->
      <v-sheet
          :style="isSticky ? `position : fixed; top : ${$store.state.avalon.header.config.height}px` : null"
          ref="navigation"
          color="transparent"
          class="pa-6"
          :width="width"
          style="pointer-events: none"
      >
        <!-- Menu Items -->
        <v-fade-transition>
          <div v-if="!bottomItersect">

            <v-hover #default="{hover}"   v-for="(item , i) in  block.config.elements" :key="'items_' + i"
                  class="mb-4"
            >
              <div class="d-flex">
                <v-sheet :color="selectedIndex === i ? CONFIG_PARAM_COLOR('styles' , block.config.style || 'light_1' , 'navigation_bg' ) :  'transparent'" class="py-2 px-5" style="border-radius: 100px;" >
                  <h4
                      style="pointer-events: auto;"
                      @click="slideToBlock(i)"
                      class="pointer"
                      :style="CONFIG_TEXT_CSS(block.config.style , 'navigation' , 'text' , null , hover  )">
                    {{ item.title }}
                  </h4>
                </v-sheet>
              </div>
            </v-hover>
          </div>
        </v-fade-transition>
      </v-sheet>
    </v-sheet>

    <!-- Content -->
    <v-sheet
        height="100%"
        ref="content"
        :width="blockWidthCalculated" color="transparent">

      <template   v-for="(childBlock,i) in children" >
        <view-block
            v-intersect="($event) => blockIntersector($event,i)"
            :ref="`child_block_${i}`"
            v-if="childBlock.type !== 'navigation' "
            :block="childBlock"
            :elements="getBlockElements(childBlock.id)"
            :key="'child_block_' + i + childBlock.id + updateTrigger"
            :navigation-width="sideNavigationWidth"
            :content-width="contentWidth"
        />
      </template>
    </v-sheet>
  </v-sheet>




</template>

<script>
import {mapState} from "vuex";
import ViewBlock from "@/components/AvalonEditor/View/blocks/viewBlock";

export default {
  name: "viewNavigation",
  components: {ViewBlock},
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    children : {
      type : Array,
      default() { return [] }
    },
    childrenElements : {
      type : Array,
      default() { return [] }
    },
  },
  data() {
    return {
      updateTrigger : 0,
      navigationTopPosition : null,
      isSticky : false,
      bottomItersect : false,
      width : 300,
      blockWidth : 1140,
      windowWidth : 1140,
      selectedIndex : 0,
      isScrolling : true
    }
  },
  computed : {
    blockWidthCalculated() {
      if ( this.SM ) {
        return this.blockWidth
      }
      return this.blockWidth - this.width;
    },
    ...mapState('avalon' , ['scrollPosition']),
    sideNavigationWidth() {
      return this.block.config.sidebar_width || 300
    },
    contentWidth() {
      let blockWidth = this.block.config.width || 1140
      return blockWidth - this.sideNavigationWidth
    }

  },
  watch : {
    scrollPosition() {
      this.getNavigationTopPosition()
    }
  },
  methods : {
    slideToBlock(index) {
      this.isScrolling = true
      this.selectedIndex = index
      this.$vuetify.goTo(this.$refs[`child_block_${index}`][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })

      setTimeout(() => {
        this.isScrolling = false
      },400)
    },
    getBlockWidth() {
      this.GET_WINDOW_WIDTH()

      this.windowWidth = this.WINDOW_WIDTH
      let blockWidth = this.block.config.width || 1140

      if ( this.SM ) {
        this.blockWidth = this.windowWidth
      } else if ( this.windowWidth < blockWidth) {
        this.blockWidth = this.windowWidth
      } else {
        this.blockWidth = blockWidth
      }

    },

    getNavigationTopPosition() {
      let content = this.$refs.content.$el
      let rect = content.getBoundingClientRect()
      if ( rect.y < ( this.HEADER_TOP_PADDING ) ) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    intersector(entries) {

      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.bottomItersect = false
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.bottomItersect = true
        }
      }
    },
    blockIntersector(entries , index ) {
      if ( this.isScrolling ) {
        return
      }
      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.selectedIndex = index - 1
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.selectedIndex = index
        }
      }
    },

    getBlockElements(id) {
      if (this.childrenElements.length === 0 ) {
        return []
      }
      return this.childrenElements.filter(el=>el.block_id === id)
    },
  },
  mounted() {
    setTimeout(() => {
      this.isScrolling = false
    },500)

    this.getBlockWidth()
    this.$nextTick(() => {
      this.getBlockWidth()
      this.updateTrigger++
    })
  },
  beforeMount () {
    this.GET_WINDOW_WIDTH()
    this.getBlockWidth()
    if(this.$options.components)
      this.$options.components.ViewBlock = require("@/components/AvalonEditor/View/blocks/viewBlock").default
  }
}
</script>

<style scoped>

</style>